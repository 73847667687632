import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from '_environments/environment';
import { RsSentry } from '@lib/utils/rs-senty-init';

new RsSentry({
  dsn: 'https://6b261d2f39a847e4951e15eaf448cf9b@sentry.io/2634137',
  enabled: environment.enableSentryMonitoring,
  environment: environment.environment,
  sentryReleaseName: environment.sentryReleaseName
}).sentryInit();

Sentry.getCurrentScope().setTag('app.environment', environment.environment);
Sentry.getCurrentScope().setTag('app.name', 'TRACE');


@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  public handleError(error: { originalError: unknown; }): void {
    Sentry.captureException(error.originalError || error);
  }

}
