import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent implements OnInit {
  public readonly appVersion: number;
  public readonly title = 'TRACE';
  public topBarEnvironment?: string;
  public topBarCdn?: string;

  public constructor(
    private readonly router: Router,
    private readonly titleService: Title,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.appVersion = environment.version!;
  }

  public ngOnInit(): void {
    this.topBarEnvironment = environment.topBarEnvironment;
    this.topBarCdn = environment.topBarCdn;
    this.setPageTitle();
  }

  private setPageTitle(): void {
    const appTitle = this.titleService.getTitle();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const child = this.activatedRoute.firstChild;
          if (child?.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      )
      .subscribe((title: string) => {
        this.titleService.setTitle(title);
      });
  }

}
